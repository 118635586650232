.requestcallback {
  height: 500px;
  background-size: contain;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 505px) {
 

  .requestcallbackleft {
    top: -591px !important;
  }

 
}

/* @media only screen and (min-width: 705px) and (max-width: 990px) {
  .requestcallback {
  }
  .requestcallbackleft {
  }
}
@media only screen and (min-width: 990px) and (max-width: 1210px) {
  .requestcallback {
  }
  .requestcallbackleft {
  }
} */


.requestcallbackleft {
  background: rgba(222, 222, 222, 0.79);
  padding-left: 0px !important;
  padding-right: 0px !important;
  position: relative;
  top: -690px;
}

@media only screen and (min-width: 1280px) and (max-width: 1500px) {
  .requestcallbackleft {
    top: -613px !important;
  }
}

@media only screen and (min-width: 1232px) and (max-width: 1280px) {
  .requestcallbackleft {
    top: -575px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1232px) {
  .requestcallbackleft {
    top: -565px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .requestcallbackleft {
    top: -480px !important;
  }
}
@media only screen and (min-width: 695px) and (max-width: 767px) {
  .requestcallbackleft {
    top: -831px !important;
  }
}
@media only screen and (min-width: 553px) and (max-width: 694px) {
  .requestcallbackleft {
    top: -735px !important;
  }
}
@media only screen and (min-width: 493px) and (max-width: 552px) {
  .requestcallbackleft {
    top: -726px !important;
  }
}
@media only screen and (max-width: 492px) {
 

  .requestcallbackleft {
    top: -591px !important;
  }

 
}
.requestcallbacklefttop {
  background: rgba(238, 88, 93, 1);
  position: relative;
}

.contentpara {
  position: relative;
  top: -800px;
  background: rgba(222, 222, 222, 0.79);
}

.contentpara1 {
  position: relative;
  top: -800px;
  background: rgba(222, 222, 222, 0.79);
}

.requesttext {
  border: 1px solid rgb(224, 205, 205) !important;
  background-color: white;
  height: 80px;
  padding: 30px;
  color: #999;
  border-radius: 20px;
  scrollbar-width: none;
}

.requesttext::placeholder {
  color: #999;
}

.requesttext:focus {
  color: #999;
  outline: none;
  padding: 5px 20px;
}

.requestinput {
  border: 1px solid rgb(224, 205, 205) !important;
  background-color: white;
  height: 40px;
  border-radius: 30px;
  width: 97%;
  padding: 0px 20px;
  color: #999;
}

.requestinput::placeholder {
  color: #999;
}

.requestinput:focus {
  color: #999;
  outline: none;
  padding: 0px 20px;
}

.formrequestbtn {
  background: rgba(238, 88, 93, 1);
  border-radius: 30px;
  color: white;
  border: none;
}

.requestinputlb {
  font-size: 16px !important;

}

.buttonpart {
  margin-top: -500px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.description {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
}

.features {
  font-size: 16px;
  line-height: 1.6;
  list-style-type: disc;
  padding-left: 20px;
}

.features li {
  margin-bottom: 10px;
}