.slick-next {
    right: 0px !important;
}

.oueprd {
    letter-spacing: 10px;

}

.ourprdimg {
    height: 160px;
    width: 600rem;
}

.ourprdcard {
    padding: 10px;
    transition: transform 0.3s ease-in-out;
}

.slick-center .ourprdcard {
    transform: scale(1.2);
    padding: 20px;
    z-index: 1;
}


.oueprd {
    font-size: 2.5rem;
}

@media (max-width: 1024px) {
    .oueprd {
        font-size: 1.5rem;
    }


}

@media (max-width: 600px) {
    .oueprd {
        font-size: 25px !important;
        font-weight: 700;
    }

    .ourprdimg {
        width: 80%;
    }

    .slick-center .ourprdcard {
        padding: 5px !important;
    }
}