.nav-pills .nav-link.active {
  border: 3px solid rgba(232, 76, 82, 1);
  background-color: rgba(232, 76, 82, 1) !important;
  color: white;
}

.tabbuttons {
  border: 3px solid rgba(232, 76, 82, 1);
  color: black;
  font-size: 20px;
}
tr,
th,
td {
  border: 1px solid rgb(199, 193, 193);
}
Table {
  border: 1px solid rgb(0, 0, 0) !important;
}
.nav-pills .nav-link {
  border-radius: 0px !important;
}

.nav-pills .nav-link:hover {
  color: black !important;
}

.nav-pills .nav-link.active:hover {
  color: rgb(255, 255, 255) !important;
}
