.formImg2 {
  position: absolute;
  bottom: 100px;
  width: 330px;
  right: 150px;
}

.nvlink:hover {
  color: #ee585d !important;
}

@media only screen and (max-width: 479px) {
  .formImg {
    position: relative;
    z-index: 2;
    left: 67%;
    width: 25%;
    bottom: 288px;
  }

  .formImg2 {
    position: absolute;
    left: 5%;
    top: 575%;
    bottom: 290px;
    height: 195px;
    width: 350px;
  }

  .sendmessagebtn {
    margin-top: 280px;
  }
}


.captcha-container {
  width: 200px !important;
  transform-origin: 0 0;
  /* Adjust the origin to keep the position consistent */
  margin-left: 7px;
}

.recaptcha-container {
  transform: scale(0.75);
  /* Adjust the scale value as needed */
  transform-origin: 0 0;
  /* Ensure the scaling is done from the top-left corner */
  width: 100%;
  height: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
/* input[type=number] {
  -moz-appearance: textfield;
} */

.slick-prev:before,
.slick-next:before {
  opacity: 1;
  color: #6e6e6e !important;
  font-size: 25px;
}

img {
  pointer-events: none;
  -webkit-user-drag: none;

}