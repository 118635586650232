@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.shopnow {
  background: url("../../assets/img/Home/image112.png");

  height: 450px;
  background-repeat: no-repeat;
  background-position: center;
}

.shopnow h1 {
  font-size: larger;
  color: white;
  font-size: 70px;
}

.shopnow h2 {
  font-weight: 400;
  color: white;
  font-size: 30px;
}

.ok {
  background: linear-gradient(252.95deg,
      #ffffff 28.58%,
      rgba(235, 235, 235, 0.47) 63.27%,
      rgba(115, 115, 115, 0) 83.38%);
  height: 350px;
}

.imshopnow {
  /* background-image: url('../../assets/img/Home/POSITIVE 1.png'); */
  position: relative;
  top: -50px;
  height: 400px;
  /* width: 350px; */
}

@media screen and (max-width: 570px) {
  .testback {
    background: rgb(255, 255, 255);
    border-radius: 15px;
    box-shadow: 4px 4px 40px 0px #00000040;
    height: auto !important;

    padding-top: 60px;
    margin: 20px;
  }
}

@media screen and (max-width: 970px) {
  .imshopnow {
    width: auto !important;
  }

  .shopnow {
    background: url("../../assets/img/Home/image112.png");

    height: 500px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .imshopnow {
    position: static;
    top: 0px;
  }

  .bannerH {
    position: relative;
    right: 100px;
    padding-top: 10px;
    text-align: center;
    font-size: 30px;
    padding-left: 100px !important;
  }
}

.contact-btn {
  color: white;
  border-radius: 30px;
  border: 1px red solid;
  background: transparent;
  width: 150px;
  height: 50px;
}

.imshopnow h1 {
  font-size: larger;
  color: white;
  font-size: 30px;
}

.imshopnow h3 {
  font-size: larger;
  color: white;
  font-size: 20px;
}

.imshopdata {
  padding-top: 210px;
}

.testomonialprofile {
  border-radius: 50%;
  height: 130px;
  width: 130px;
  border: 7px solid rgb(242, 236, 236);
  box-shadow: 4px 4px 40px 0px #00000040;
  text-align: center;
  align-content: center;
  color:  white;
  font-family: 'News706 BT', sans-serif; /* Fallback to sans-serif */
  font-weight: bold; /* Ensure it uses the bold weight */

  /* background: url('../img/aa/POSITIVE.png'); */
  /* background-image: url('../img/aa/youer.jpeg'); */
  background-size: cover;
  background-repeat: no-repeat;

}
.profileposition {
  position: relative;
  top: 70px;
  justify-content: center;
  display: flex;


}

.testomonialback {
  background: rgba(239, 239, 239, 1);
  position: relative;
}

.testback {
  background: rgb(255, 255, 255);
  border-radius: 15px;
  box-shadow: 4px 4px 40px 0px #00000040;
  height: 330px;
  padding: 30px;
  padding-top: 60px;
  margin: 20px;
}

.testback2 {
  background: rgb(255, 255, 255);
  border-radius: 15px;
  box-shadow: 4px 4px 40px 0px #00000040;
  height: auto !important;
  padding: 10px;
  padding-top: 60px;
  margin: 10px;
}

.testback h1 {
  font-size: 30px;
  letter-spacing: 3px;
  font-weight: 700;
}
.testback2 p {
  text-align: justify;

}
.testback p {
  font-size: 16px;
  text-align: justify;
}

.star {
  color: gold;
  margin: 7px;
}

.star1 {
  color: rgb(158, 151, 151);
  margin: 7px;
}

.BannerT a {
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  letter-spacing: 3px;
}

.bannerH {
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-size: 40px !important;
}

@media screen and (min-width: 1600px) {
  .BannerT {
    font-size: 20px !important;
  }
}