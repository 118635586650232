.homebannerback {
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #f0f0f0;
}

.carousel-control-prev,
.carousel-control-next {
    display: none;
}

.sticky-top {
    position: static;
}

  
@media (max-width: 700px) {
    .carsimg {
        height: 400px !important;
    }

    .homebannertext {
        background: transparent !important;
        text-align: left;
        position: static !important;
        top: 0px !important;
        padding: 0px !important;
        /* padding-right: 190px; */
        margin-right: 0px !important;
        margin-left: 0px !important;

    }

    .bannerform {
        display: none !important;
    }

}

.homebannerback2 {
    width: 100%;
    background-color: #ece5e5;
    height: 400px;
    background-image: url('../img/Home/image38.png');
    background-size: contain;
}



.homebannertext {
    background: linear-gradient(to left, rgba(238, 88, 93, 0) 0%, rgba(238, 88, 93, 0.2) 18.61%, rgba(238, 88, 93, 0.814196) 61.08%, #EE585D 81.38%);
    text-align: left;
    position: relative;
    top: -340px;
    padding: 50px;
    padding-right: 190px;
    /* margin-right: -200px; */
    margin-left: 100px;

}

.contentbanner{
    position: absolute;
}

.bannerform {
    background: transparent;
    color: white;
    border: 3px solid white;
    width: 300px;
    position: relative;
    left: 75%;
    top: -850px;
}
@media screen and (min-width: 1800px) {
    .bannerform {
        top: -690px;
    }
}


@media screen and (min-width: 1900px) {
    .bannerform {
        left: 0%;
    }
}

.bannerform2 {
    background: transparent;
    color: white;
    border: 2px solid white;
}

.bannerinp {

    width: 250px;

    padding-bottom: 20px;
    padding-top: 20px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: white !important;

    background-color: transparent !important;
    background-clip: padding-box;
    border-bottom: 1px solid white !important;
    border-radius: 0px !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    height: 40px;

}

.bannerinp::placeholder {
    color: white !important;

}

.bannerinp:focus {
    outline: none !important;
}

.bannertxtarea {
    display: block;
    width: 250px;
    /* margin-left: 10px; */
    padding: 5px;
    margin-top: 25px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: white !important;

    background-color: transparent !important;
    background-clip: padding-box;
    border: 1px solid white !important;
    border-radius: 0px !important;
    height: 100px;
}
.bannertxtarea2 {
    display: block;
    width: 250px;
    margin-left: 7px!important;
    padding: 5px;
    margin-top: 25px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: white !important;

    background-color: transparent !important;
    background-clip: padding-box;
    border: 1px solid white !important;
    border-radius: 0px !important;
    height: 100px;
}

.bannertxtarea:focus {
    outline: none !important;
}

.bannertxtarea::placeholder {
    color: white !important;

}

.bannerbtn {
    border: none;
    background-color: #EE585D;
    color: white;
    border-radius: 30px;
    letter-spacing: 2px;
    font-weight: 500;
}

.graycircle {
    height: 300px;
    width: 300px;
    background: #989F9D;
    border-radius: 50%;
}

.chartslider {

    left: 300px;
}


.box {
    box-shadow: 8px 8px 4px 0px #00000040;

}

.pulgertitle {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 700;

}

.productapplictions {
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 2px;
    font-weight: 500;
}

@media screen and (min-width: 1600px) {
    .productapplictions {
        font-size: 20px !important;
        letter-spacing: 1px;
        font-weight: 500;
    }

}
@media screen and (max-width: 800px) {
    .productapplictions {
        font-size: 16px !important;
        letter-spacing: 0px;
        font-weight: 500;
    }
}
.bannertext{
    font-size: 13px !important;
}
.pulgersubtitle {
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.pulgerlist {
    line-height: 30px;
}