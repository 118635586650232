.formImg{
  position: absolute;
  width: 400px !important;

  bottom: 50px;
  right: 180px;
}
.creerback{
  position: relative;background: rgb(239, 239, 239);
}
.upcv{
  letter-spacing: 8px;
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
    .formImg {
        position: relative;
        z-index: 2;
        left: 10%;
        width: 200px !important;
        top: -280px;
      }
      .sendmessagebtn{
        margin-top: 200px;
      }
  
  }