.visionMission {
  background-image: url("../img/About/vision.png");
  height: 250px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.visionMissionp {
  font-size: 18px;
}
.alignbtn {
  margin-right: 15%;
}
.abouttxt {
  font-size: 15px !important;
}
.firstWord {
  font-size: 2rem !important;
}
.restOfText {
  font-size: 1.5rem;
}

@media screen and (max-width: 670px) {
  .visionMission {
    background-image: url("../img/Untitled-1\ 2\ \(1\)\ \(1\).png");
    height: 300px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .firstWord {
    font-size: 3rem !important;
  }
  .restOfText {
    font-size: 1.5rem;
  }
  .alignbtn {
    margin-right: 1%;
  }
}

@media screen and (min-width: 1400px) {
  .producttabimg {
    height: auto !important;
  }
}

.producttabimg {
  height: 500px;
}

.infrastructurecard {
  box-shadow: 20px 10px 80px 0px #00000040;
}
.infrastructurecardinfo {
  /* height: 170px !important; */
}
.team-card {
  transition: background-color 0.3s, color 0.3s;
  border: 1px solid #c8c8c8;
  box-shadow: 8px 10px 10px 0px #00000040;
  color: white !important;
  background: #cd4247;
}

.black-white-image {
  filter: grayscale(100%);
  transition: filter 0.3s ease-out;
}

.colored-image {
  filter: none;
  transition: filter 0.3s ease-out;
}

.card-img-top {
  width: 100%;

  object-fit: cover;
}

.plungercard {
  box-shadow: 5px 5px 0px 0px #00000040;
  background: #efefef;
  height: 230px;
  width: 170px !important;
}
.carousel-indicators [data-bs-target]{
  background-color: black !important;
}
@media (max-width: 726px) {
  .prdimg {
    height: 150px !important;
    width: 150px !important;
  }
  
  .plungercard {
    box-shadow: 5px 5px 2px 0px #00000040;
    background: #efefef;
    height: 230px;
    width: 150px !important;
  }
  .producttabimgscr{
    height: 200px;
    width: 100%;
  }
  .teamimg {
    height: 300px;
    width: 100%;
  }
  .producttabimg {
    height: 300px !important;
  }
  .eventimg {
    height: 250px !important;
    width: 350px !important;
  }
  .evntttle {
    width: 320px !important;
  }

  .social-icons .icon {
    font-size: 3rem !important;

    padding: 5px;
    margin: 5px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s, color 0.3s, box-shadow 0.3s;
  }

  .textinfff {
    padding-left: 5px !important;
  }
}
.textinfff {
  padding-left: 20px;
}
.prdimg {
  height: 150px !important;
  width: 150px !important;
}

.sencodetext {
  background: #cd4247;
  height: 133px;
}

.teamimg {
  height: 400px;
  width: 100%;
}
.abtname {
  color: #e84c52;
}
.sencodesubtext {
  background: #e84c52;
  position: relative;
  bottom: 60px;
}

.invisible {
  display: none;
}

.getintouchbg {
  background: url("../img/Contactus/background6.png");
}

.leadershippara {
  background-color: #f7f5ef;
}

.spaceing {
  margin-bottom: 10px;
}

.social-icons {
  padding-left: 20px;
}

.social-icons .icon {
  font-size: 4rem;

  padding: 10px;
  margin: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, color 0.3s, box-shadow 0.3s;
}

.social-icons .icon:hover {
  transform: translateZ(10px) rotateY(15deg);
  color: #e84c52; /* Change this color as needed */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
@media (min-width: 526px) and (max-width: 1300px) {
  .eventimg {
    height: 220px !important;
    width: 340px !important;
  }
}
.eventimg {
  height: 300px;
  width: 400px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
}
.evntttle {
  background: #e84c52;
  position: relative;
  bottom: 60px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);

  color: white;
  width: 350px;
}
.producttabimgscr{
  height: 400px;
}
