.produclistfttop {
  background: rgba(238, 88, 93, 1);
}

.produclistcontetst {
  color: white;
  list-style: none;
  font-size: 16px;
  border-bottom: 1px solid rgb(235, 213, 213);
}

.productlistimgssubtitle {
  letter-spacing: 4px;
  position: relative;
  top: 50px;
  padding-left: 150px;
}

.productlistimgstitle {
  font-size: 70px;
  letter-spacing: 8px;
  font-weight: 900;
  color: rgba(216, 211, 211, 1);
  position: relative;
  top: 40px;
  padding-left: 150px;
}

.productlistproductimg {
  position: relative;
  top: -90px;
  left: 100px;
}
.productlistimgsapplication {
  font-size: 13px;
}
@media screen and (max-width: 670px) {
  .productlistimgssubtitle {
    letter-spacing: 4px;
    position: static;
    top: 0px;
    padding-left: 0px;
  }
  .produclistcontetst {
    color: white;
    list-style: none;
    font-size: 13px;
    border-bottom: 1px solid rgb(235, 213, 213);
  }
  .productlistimgstitle {
    font-size: 70px;
    letter-spacing: 4px;
    font-weight: 900;
    color: rgba(216, 211, 211, 1);
    position: static;
    top: 0px;
    padding-left: 0px;
  }

  .productlistproductimg {
    position: static;
    top: 0px;
    left: 0px;
  }
  .productlistimgsapplication {
    font-size: 13px;
  }
}

#menu {
  background-color: #e84c52;
  color: white;
  border-radius: 0px 50px 0px;
  box-shadow: 8px 8px 20px 9px #00000040; cursor: pointer; 
}
.product-list-item-selected {
  font-weight: bold;
}
.prdimgs {
  /* background-image: url("../img/productimgs/image\ 7.png"); */
  /* background-image: url("../img/Group\ 1000004140\ \(1\)\ \(1\).png"); */
  background-size: cover;
}
.horizontal-scroll {
  overflow-x: auto;
  white-space: nowrap;
}