.caontatctcardback {
  background: #ffffffcc;
}




.caontatctcardimg {
  position: relative;
}

.txtcontact1 {
  font-size: 14px;
}

@media screen and (max-width: 1300px) {
  .caontatctcardsubsection {
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 8px 10px 25px 0px rgba(0, 0, 0, 0.25);
    width: 300px !important;
    position: absolute;
    left: 60px;
    bottom: 0px;
  }

  .txtcontact {
    font-size: 11px !important;
  }

  .digital-icon {
    color: #ffffff;
    padding: 7px !important;
    transition: all 0.3s ease;
    border: 1px solid rgb(120, 118, 118);
    background-color: rgb(120, 118, 118);
    border-radius: 50%;
    font-size: 40px !important;
  }

}
.digital-icon {
  color: #ffffff;
  padding: 3px !important;
  transition: all 0.3s ease;
  border: 1px solid rgb(120, 118, 118);
  background-color: rgb(120, 118, 118);
  border-radius: 50%;
  font-size: 30px !important;
}

.caontatctcardsubsection {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 8px 10px 25px 0px rgba(0, 0, 0, 0.25);
  width: 400px;
  position: absolute;
  left: 60px;
  bottom: 0px;
}

.txtcontact {
  font-size: 14px;
}

@media screen and (min-width: 1800px) {
  .caontatctcardsubsection {
    width: 410px;
    position: absolute;
    left: 100px;
    bottom: 0px;
  }

  .caontatctcardsubsection p {
    font-size: 17px;
  }

}