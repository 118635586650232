#getaquote_button {
    position: relative;
    right: -7rem;
    top: -4rem;

}

.blogdetailimg {
    width: 100%;
    height: 500px;    
}

@media only screen and (max-width: 600px) {
    #getaquote_button {
        position: relative;
        right: -6rem;
        top: -4rem;
    }
    .blogdetailimg {
        width: 100%;
        height: 350px;    
    }
    
}

@media only screen and (min-width:480px) {
    #getaquote_button {
        position: relative;
        right: -6rem;
        top: -4rem;

    }
}

@media only screen and (min-width:1025px) {
    #getaquote_button {
        position: relative;
        right: -8rem;
        top: -4rem;

    }
}

.getquoteformback {
    background-image: url('../assets/img/Group\ 1000004140\ \(1\)\ \(1\).png');
    /* D:\newpositive\positive_metering_front\src\assets\img\About\back 21.png */
    background-size: cover    ;
    background-repeat: no-repeat;
    opacity: 0.7;
}

.formbacks {
    background: rgba(255, 255, 255, 0);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    /* backdrop-filter: blur(3px); */
    /* -webkit-backdrop-filter: blur(1px); */
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
}