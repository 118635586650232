* {
  font-family: 'Poppins';
}



.dropdown-menu {
  border-radius: 0 !important;
  padding: 0;
  margin: 0;
  text-align: center;
}


.smglink {
  text-decoration: none !important;
  color: #000000;
}

.dropdown-menu-btn {
  letter-spacing: 2px;
  text-align: center;
}

.dropdown-item.active,
.dropdown-item:active {
  background: transparent !important;
}

.headercontact {
  border: 1px solid #010101;
  border-radius: 0px 100px 100px 0px;
  border-left: none;
  letter-spacing: 2px;
}

.headercontact2 {
  border: 1px solid #010101;
  border-radius: 100px 0px 0px 100px;
  border-right: none;
  letter-spacing: 2px;
}

.headerphone {
  color: black;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 2px;
}

.header-logo {
  position: absolute;
  width: 100px;
  left: 47.5%;
  bottom: -6px;
}

@media screen and (min-width: 1400px) {
  .header-logo {
    position: absolute;
    width: 130px;
    left: 48.5%;
    bottom: -14px;
  }
  .headerphone {
    color: black;
    font-size: 17px !important;
    font-weight: 700;
    letter-spacing: 2px;
  }
  .navbarspacing {
    margin-bottom: 15px !important;
  }

  .hedershadow {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .header-logo.small-logo {
    width: 80px !important;
    padding: 0px !important;
    left: 0px;
    bottom: -18px !important;
  }

}

.navbarspacing {
  margin-bottom: 5px;
}

.navbar {
  padding: 0px !important;
}


.blogspace {
  margin-right: 100px;
}

.hedershadow {
  box-shadow: 8px 10px 10px 0px #00000040;
  padding-top: 5px;
}

.header-logo.small-logo {
  width: 50px;
  padding: 0px !important;
  left: 0px;
  bottom: -5px;
}


.callicon {
  color: #e2c3c3;
  font-size: 20px;
}

.header-wrapper {
  transition: top 0.3s;
}

.header-wrapper.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: white;
}

.footertext {
  text-align: justify;

}

.footerinputbox {
  background-color: white;
  border-radius: 10px;
}

.footerenqury {
  background: #E84C52;
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  letter-spacing: 2px;
  text-align: center;
}

.emailfeild {
  width: 220px !important;
}

.subscribe {
  width: 80%;
}

.footerenquriybtn {
  float: inline-end;
  border-radius: 10px !important;
  background: #433C68 !important;
  padding: 10px;
  border: none !important;
}

.icon-hover {
  background-color: white;
  color: gray;
  border-radius: 50%;
  padding: 7px;
  font-size: 39px;
}

.icon-hover:hover {
  background-color: #e15554;
  color: white;
}


.footerback {
  background: url("./assets/img/Home/image29.png");
  background-size: cover;
  font-size: 15px;
}

.footertext {
  text-align: left
}

@media (max-width: 526px) {
  .footertext {
    text-align: center;
  }

  .emailfeild {
    width: 145px !important;
  }


  .footerenqury {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    letter-spacing: 2px;
    text-align: center;
  }

  .subscribe {
    width: 100%;
  }
}

@media (min-width: 526px) and (max-width:920px) {
  .footertext {
    text-align: center;
  }

  .emailfeild {
    width: 270px !important;
  }

  .subscribe {
    width: 100%;
  }

  .footerenqury {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}




.newsEventTitleFont {
  font-family: "Poppins", sans-serif;


}

.newsEventDescFont {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}


.blogCardTitle {
  font-family: "Poppins", sans-serif;

}

.blogCardDesc {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.mobile-navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: transparent;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.navbar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  background-color: transparent;
}

.logo {
  height: 45px;
}

.toggle-button {
  font-size: 18px;
  background: none;
  border: none;
  cursor: pointer;
  color: #000000;
  background-color: white;
  padding: 6px;
  margin-right: 10px;
  z-index: 1001;
}

.hrline {
  color: white !important;
}

.navbar-links {
  position: fixed;
  top: 0;
  left: -100%;
  width: 250px;
  height: 100%;
  background-color: #333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: left 0.3s ease;
  z-index: 999;
}

.navbar-links.open {
  left: 0;
}

.navbar-links a {
  color: #ffffff !important;
  text-decoration: none;
  padding: 15px 20px;
  display: block;
}

@media (max-width:1400px) {
  
.homeaboutinfo{
  font-size: 10px !important;
}
}
.blogcrd {
  height: 340px !important;
}
.blogbtnr{
  position: relative;
  bottom: 2px;
}
.closeButton{
  position: absolute;
}
.pstext {
  text-align: justify;
}


.homeaboutinfo{
  font-size: 16px;
}

.abcd{
  background: url('./assets/img/News/okkok.jpg');
  background-size: cover;
  
}